import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form } from '../../../../components/form/form.component';
import { FormHeader } from '../../../../components/form/header.component';
import { FormSections, SectionsWrapper } from '../../../../components/form/sections.component';
import { Role, RoleAssignment } from '../../../../entities/role.entity';
import { FormMode, ModuleName } from '../../../../enums/core.enum';
import { FormHeaderType, FormStyle } from '../../../../enums/form.enum';
import { IPaginationOptions, IPaginationResponse } from '../../../../interfaces/paginate.interface';
import { AclService } from '../../../../services/acl.service';
import { Api, Endpoint } from '../../../../services/api.service';
import { capitalize } from '../../../../util/string.util';
import { UserRoute } from '../../routes';
import { UserRolePostAssignment } from '../assignment.component';
import { getAllAssignments } from '../role.util';
import { BasicRolePost } from './section/basic.component';
import { RolePostInstructions } from './section/instructions.component';

/**
 * The props that the post component is expecting in case if it is to be used inside the list page
 * style (optional): The form style currently contains just one style, and it's Containerized, i.e. to be used inside the list page
 * cancel (optional): Function that needs to be passed to switch between editing state or listing state of the list page
 * formMode (optional): Property that overrides the default formMode state of the Form
 * entityId (optional): Needs to be passed if the form is to be used for editing the entity
 */
interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
}


/**
 * User Role Post
 */
export function UserRolePost({
  style, cancel, formMode, entityId
}: Props) {

  /**
   * The states of the post component
   * selectedModule: Controls the list of the assignments to be shown, i.e. show only the acl assignments that belong to a particular module
   * roles: The list of the roles retrieved from the backend
   * loading: The overriding property for the form's default loading state
   */
  const [selectedModule, setSelectedModule] = useState<ModuleName>(ModuleName.Dashboard)
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [protectedRole, setProtectedRole] = useState<Role>();
  const [tempAssignments, setTempAssignments] = useState<RoleAssignment[]>([]);

  /**
   * The useForm is used here as the post component itself needs to use the capabilities provided by the react hook forms
   * So, the child, i.e. Form, component will use this form instance rather than creating new one
   * PSST... That's exactly why "useParentFormContext" is being used 
   */
  const form = useForm();
  const { control, setValue, getValues, reset } = form;

  /**
   * Using the useFieldArray as the list of acl assignments are dynamic, so creating them on the go
   * It creates the assignments list as form inputs irrespective of the number of modules and assignments
   */
  let { fields: assignmentFields, append: appendAssignment, remove: removeAssignment } = useFieldArray({
    control,
    name: 'assignments',
  });

  /**
   * Using translations from forms and main namespaces
   * To get an idea from where the translations are being loaded, check out the resources key in the services/i18n file
   * against the respective namespace
   */
  const { t } = useTranslation('main');
  const translationList = useTranslation('list')
  const { push: navigateTo } = useHistory();

  /**
   * Init Function
   */
  const init = useCallback(async () => {
    setLoading(true);

    /* Loading the role list from the backend */
    const roleList = await Api.get<IPaginationResponse<Role>, IPaginationOptions>(Endpoint.USER_ROLE_LIST);

    /* Setting the roles list state */
    setRoles(roleList.items);

    setLoading(false);

    /* In case if the form is being edited, then don't load the assignments list as it will be provided by the load function */
    if (formMode !== FormMode.Editing && assignmentFields.length === 0) {
      appendAssignment(getAllAssignments(selectedModule));
    }


  }, [selectedModule, appendAssignment, formMode, assignmentFields]);

  /**
   * useEffect Hooks
   */
  useEffect(() => { init(); }, [init]);
  useEffect(() => {
    /** The loop renders the assignments list as created by the Controller component at the bottom of the file */
    for (let i in assignmentFields) {
      const key = `assignments[${i}].name`;
      const value = getValues(key);
      /* Set the field values for the input fields as created by the controller component */
      setValue(key, { ...value, show: value.module === selectedModule });
    }
  }, [selectedModule, assignmentFields, getValues, setValue])

  /**
   * Load function
   */
  const load = async (id: string) => {
    /* Loading the to-be-edited role details from the backend */
    const role = await Api.get<Role, { id: string }>(Endpoint.USER_ROLE, { id });

    setProtectedRole(role);
    /**
     * Store its assignments list in a temporary variable and remove the assignments key
     * as the assignments are to be rendered and dealt with seperately
     * rather than being directly filled in the form by the reset command used below
     */
    const roleAssignments: RoleAssignment[] = role.assignments ? [...role.assignments] : [];
    delete role.assignments;

    /* Fills the form with the values returned by the backend in the role variable */
    reset(role);

    /* Load the entire assignments list and show only the assignments that belong to the current module */
    const displayAssignments = getAllAssignments(selectedModule);

    /**
     * Looping through the list of assignments received from backend
     * Comparing its properties with the original assignments list
     * And updating the original list, so that it shows all checked and unchecked assignments
     */
    for (let roleAssignment of roleAssignments) {
      for (let displayAssignment of displayAssignments) {
        if (roleAssignment.module === displayAssignment.module
          && roleAssignment.action === displayAssignment.action.code) {
          displayAssignment.id = roleAssignment.id;
          displayAssignment.checked = true;
        }
        /* Show only those that belong to the particular module */
        displayAssignment.show = selectedModule === displayAssignment.module;
      }
    }
    setTempAssignments(roleAssignments)
    /* Clear the assignments list */
    removeAssignment();

    /* Append the modified assignments list */
    appendAssignment(displayAssignments);
  }

  /**
   * Save form function
   */
  const save = async (form: Role) => {
    /* Filter the checked assignments and map them to the new variable containing only id, action, module, and role */
    let assignments: RoleAssignment[] = form.assignments ? form.assignments.filter((assignment: RoleAssignment) => assignment.name.checked)
      // .map((assignment: RoleAssignment) => ({
      //   id: assignment.name.id,
      //   action: assignment.name.action.code,
      //   module: assignment.name.module,
      //   role: form.id,
      // })) : [];
      : []
    let dataFinal: any = []
    let deletedAssignments: RoleAssignment[] = []
    console.log('This is form data:', tempAssignments, assignments)
    assignments = assignments.filter((assignment: RoleAssignment) => {
      let flag = true;
      const data = tempAssignments.filter(function (tempAssignments) {
        if (tempAssignments.module == assignment.name.module && tempAssignments.action == assignment.name.action.code) // return the ones with equal id
        {
          flag = false;
          return assignment;
        }
      });
      if (flag) {
        dataFinal.push(assignment);
      }
      return data;
    })
    const datatemp=tempAssignments.filter((tempAssignments: RoleAssignment) => {
      let flag = true;
      const data = assignments.filter(function (assignment) {
        if (tempAssignments.module == assignment.name.module && tempAssignments.action == assignment.name.action.code) // return the ones with equal id
        {
          flag = false;
          return assignment;
        }
      });
      if (flag) {
        deletedAssignments.push(tempAssignments);
      }
      return data;
    })
    dataFinal= dataFinal?.map((assignment: RoleAssignment) => ({
    id: assignment.name.id,
    action: assignment.name.action.code,
    module: assignment.name.module,
    role: form.id,
  }));
    // console.log('form', form)

    /* In case if a form id is detected, then use that otherwise set it to undefined */
    form.id = form.id || undefined;

    /* Use the update functionaltiy if form has an id, otherwise use post */
    const saveFn = form.id ? Api.patch.bind(Api) : Api.post.bind(Api);

    /* Attach the assignments to the form */
    form.addAssignments = dataFinal;
    form.deleteAssignments = deletedAssignments;
    // console.log('This is form data:', tempAssignments, form)
    /* Save the role details in the database */
    await saveFn<Role, Role>(Endpoint.USER_ROLE, form);

    /**
     * In case a cancel function is provided use that otherwise, navigate to a particular page
     */
    if (cancel) {
      cancel();
    } else {
      navigateTo(UserRoute.ListRole);
    }
  }

  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.role")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.role")} />
    )
  );

  /**
   * Render
   */

  return (
    <FormProvider {...form}>
      <Form<Role>
        useParentFormContext={true}
        customLoad={load}
        saveForm={save}
        cancel={cancel}
        returnLink={UserRoute.ListRole}
        endpoint={Endpoint.USER_ROLE}
        formMode={formMode}
        entityId={entityId}
        defaultLoading={loading}
        className={style === FormStyle.Containerized && "ae-content-w"}
      >
        {/* Using the custom header in case of using containerized form */}
        {style === FormStyle.Containerized && header()}
        <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
          {/* If not containerized, then use it normally */}
          {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.role")} />}
        </SectionsWrapper>

        <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
          {/* If not containerized, then use it normally */}
          {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-12" childClassName="row">
          <FormSections className="col-md-6">
            <BasicRolePost roles={roles} isProtected={protectedRole} />
            <RolePostInstructions />
          </FormSections>

          {/* Modules list renderer */}
          <div className="ae-list-w col-md-2 element-wrapper" style={{ backgroundColor: 'transparent' }}>
            <div className="ae-list" style={{ backgroundColor: '#ffffff', height: 'auto' }}>
              {AclService.getModules().map(module => (
                <div key={module}
                  className={classNames('ae-item', { active: module === selectedModule })}
                  onClick={() => setSelectedModule(module)}
                >
                  <div className="aei-content px-4 py-1">
                    <h6 className="aei-title">{translationList.t(`roles.${module}`)}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Assignments list renderer */}
          <div className="todo-list element-box col-md-4">
            {assignmentFields.map((item, i) => (
              <Controller
                key={i}
                name={`assignments[${i}].name`}
                as={UserRolePostAssignment}
                control={control}
                defaultValue={item}
              />
            ))}
          </div>
        </SectionsWrapper>
      </Form>
    </FormProvider>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { selectedPic, show } from "../../../components/form/input/upload.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Address } from "../../../entities/address.entity";
import { Driver, DriverFileType, DriverStatus } from "../../../entities/driver.entity";
import { Group } from "../../../entities/group.entity";
import { Settings } from "../../../entities/settings.entity";
import { FormMode } from "../../../enums/core.enum";
import { Enviroment } from "../../../enums/environment.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Api, ApiType, Endpoint } from "../../../services/api.service";
import { ValidationError } from "../../../types/error.interface";
import { checkCCStatus } from "../../../util/checkCCStatus";
import { DriverRoute } from "../routes";
import { DriverPostBank } from "./section/bank.component";
import { DriverPostEmergency } from "./section/emergency.component";
import { DriverPostFeature } from "./section/feature.component";
import { DriverPostFleet } from "./section/fleet.component";
import { DriverPostGroup } from "./section/group.component";
import { DriverPostLicense } from "./section/license.component";
import { DriverPostNetwork } from "./section/network.component";
import { DriverPostOperator } from "./section/operator.component";
import { DriverPostPrivate } from "./section/private.component";
import { DriverPostPublic } from "./section/public.component";
import { DriverPostVerification } from "./section/verification.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Driver) => void;
}

interface id {
  id: string | undefined;
  picId: string | undefined;
  fileType: DriverFileType;
}
/**
 * Add or update a driver
 */
export const urlShow = React.createContext<boolean>(false);
export function DriverPost({ style, cancel, formMode, entityId, onAfterSave }: Props) {
  const { t } = useTranslation(["main", "forms"]);
  const [pic, setPic] = useState<boolean>(false);
  const [driver, setDriver] = useState<Driver>();
  const [value, setValue] = useState("");
  const [emergencyPhoneValue, setEmergencyPhoneValue] = useState("");
  const [settings, setSettings] = useState<Settings>();
  const [entityCount, setEntityCount] = useState<any>()
  const [ip, setIP] = useState<string>("");
  const [ENABLE_CC, setENABLE_CC] = useState<any>();

  const fetchSettings = async () => {
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    const count = await Api.get<any, any>(Endpoint.DRIVER_ENTITIES_COUNT);
    const cc = await checkCCStatus()
    setEntityCount(count)
    setSettings(data);
    setENABLE_CC(cc)
  }

  const getFullName = (driver: Driver): string => {
    return [driver.firstName, driver.lastName].join(" ");
  };

  const onBeforeSave = async (mode: FormMode, form: Driver) => {
    console.log('form Before Data:', form)
    if (settings?.driverRequirementAddress && form.address?.state === "") {
      // form.setError("Please enter state")
      throw new Error(
        "State not found"
      );
    }
    // if (form.phone === null) {
    //   throw new ValidationError({
    //     field: form.phone,
    //     message: `phone number should not be empty!`,
    //   });
    // }
    Object.entries(form).map(item => {
      if (form[item[0]] == '') {
        form[item[0]] = undefined;
      }
    })


    if (settings?.driverRequirementEmergencyName && form.emergencyName === getFullName(form)) {
      throw new ValidationError({
        field: form.emergencyName,
        message: `Emergency name should be different from driver name!`,
      });
    }
    if (settings?.driverRequirementEmergencyPhone && form.emergencyPhone === form.phone) {
      throw new ValidationError({
        field: form.emergencyPhone,
        message: `Emergency Email should be different from driver Email!`,
      });
    }
    if (settings?.driverRequirementEmergencyPhone && form.emergencyEmail === form.email) {
      throw new ValidationError({
        field: form.emergencyEmail,
        message: `Emergency Email should be different from driver Email!`,
      });
    }
    if (show === true) {
      if (form.newPicFile?.dataUrl === selectedPic) {
        form.newPicFile = undefined;
      }
      if (form.newIdentityFiles && form.newIdentityFiles.length > 0) {
        form.newIdentityFiles = Object.values(form.newIdentityFiles).filter(
          (file) => file.dataUrl != selectedPic
        );
      }
      if (form.newVevoFiles && form.newVevoFiles.length > 0) {
        form.newVevoFiles = Object.values(form.newVevoFiles).filter(
          (file) => file.dataUrl != selectedPic
        );
      }
      if (form.newPoliceFiles && form.newPoliceFiles.length > 0) {
        form.newPoliceFiles = Object.values(form.newPoliceFiles).filter(
          (file) => file.dataUrl != selectedPic
        );
      }
      if (form.newHistoryFiles && form.newHistoryFiles.length > 0) {
        form.newHistoryFiles = Object.values(form.newHistoryFiles).filter(
          (file) => file.dataUrl != selectedPic
        );
      }
      if (form.newLicenseFiles && form.newLicenseFiles.length > 0) {
        form.newLicenseFiles = Object.values(form.newLicenseFiles).filter(
          (file) => file.dataUrl != selectedPic
        );
      }
      if (
        form.newEnglishLanguageProficiencyFiles &&
        form.newEnglishLanguageProficiencyFiles.length > 0
      ) {
        form.newEnglishLanguageProficiencyFiles = Object.values(
          form.newEnglishLanguageProficiencyFiles
        ).filter((file) => file.dataUrl != selectedPic);
      }
      if (form.newMedicareCardFiles && form.newMedicareCardFiles.length > 0) {
        form.newMedicareCardFiles = Object.values(
          form.newMedicareCardFiles
        ).filter((file) => file.dataUrl != selectedPic);
      }
      if (form.newMedicareFiles && form.newMedicareFiles.length > 0) {
        form.newMedicareFiles = Object.values(
          form.newMedicareFiles
        ).filter((file: any) => file.dataUrl != selectedPic);
      }
      if (
        form.newProficiencyFiles &&
        form.newProficiencyFiles.length > 0
      ) {
        form.newProficiencyFiles = Object.values(
          form.newProficiencyFiles
        ).filter((file: any) => file.dataUrl != selectedPic);
      }
    }
    form.status = DriverStatus.Active;
    form.phone = value;
    form.address = {
      ...(form.address as Address),
      postalCode: form.postalCode,
    };
    form.ip = ip;
    if (form.bankAccountNumber) {
      form.bankAccountNumber = form?.bankAccountNumber.replace(/\s/g, '')
    }
    if (form.bankAccountRouting) {
      form.bankAccountRouting = form?.bankAccountRouting.replace(/\s/g, '')
    }
    if (form.networks) {
      form.networks = form.networks.map(function (network: any) {
        return { id: network.id as string };
      })
    }
    if (form.features) {
      form.features = form.features.map(function (features: any) {
        return { id: features.id as string };
      })
    }
    if (form.operators) {
      form.operators = form.operators.map(function (operators: any) {
        return { id: operators.id as string };
      })
    }
    // if (form.groups) {
    //   form.groups = form.groups.map(function (groups: any) {
    //     return { id: groups.id as string }
    //   })
    // }
    if (form.fleets) {
      form.fleets = form.fleets.map(function (fleets: any) {
        return { id: fleets.id as string };
      })
    }
    if (form.groups) {
      form.groups = form.groups.map(function (groups: any) {
        return { id: groups.id as string };
      })
    }

    if (form.badgeNumber) {
      form.licenseNumber = `${form.licenseNumber || ''}|${form.badgeNumber}`;
    }

    console.log("form data after", form)
  };

  useEffect(() => {
    getIp();
    fetchSettings();
  }, []);

  useEffect(() => {
  }, [settings]);

  const getIp = async () => {
    // const res: any = await axios.get("https://api.db-ip.com/v2/free/self");
    const res = await Api.get<any, any>(Endpoint.GET_IP, {}, ApiType.Rest)
    setIP(res);
  };

  const processData = (driver: Driver): any => {
    console.log('Editing Driver Data:', driver);
    if (driver.fleets && driver.fleets.length <= 0) {
      delete driver.fleets
    }
    if (driver.networks && driver.networks.length <= 0) {
      delete driver.networks
    }
    if (driver.groups && driver.groups.length <= 0) {
      delete driver.groups
    }
    if (driver.features && driver.features.length <= 0) {
      delete driver.features
    }
    (driver as any).identityBackFile = driver.identityFiles?.filter((item) => item.type == 'identityBack')[0];
    (driver as any).identityFrontFile = driver.identityFiles?.filter((item) => item.type == 'identityFront')[0];
    driver.groups = driver.groupDrivers.map((item: Group) => item.group)
    if (driver.licenseNumber && driver.licenseNumber.includes('|')) {
      const split = driver.licenseNumber.split('|');
      driver.licenseNumber = split[0];
      driver.badgeNumber = split[1];
    }
    setValue(driver.phone);
    setDriver(driver);
    if (driver.emergencyPhone) {
      setEmergencyPhoneValue(driver.emergencyPhone)

    }
    driver.postalCode = driver.address?.postalCode;
    return driver;
  };

  const FormEditingOnclick = async (e: any) => {
    setPic(true);
    if (driver?.picFile?.id) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, {
        id: driver.id,
        picId: driver.picFile?.id,
        fileType: DriverFileType.Pic,
      });
    }
  };

  /**
   * Custom Header
   */
  const header = () => style && style === FormStyle.Containerized
   ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader
            type={FormHeaderType.Title}
            title={t("entities.driver")}
          />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.driver")} />
    );

  /**
   * Render
   */

  return (
    settings ?
      <Form<Driver>
        endpoint={Endpoint.DRIVER}
        returnLink={DriverRoute.List}
        cancel={cancel}
        formMode={formMode}
        entityId={entityId}
        className={style === FormStyle.Containerized && "ae-content-w"}
        fileEndpoint={Endpoint.DRIVER_FILES}
        onAfterSave={onAfterSave}
        onBeforeSave={onBeforeSave}
        processData={processData}
        formFiles={[
          {
            required: settings?.driverRequirementPicFile ? true : false,
            count: 1,
            originalName: "picFile",
            name: "newPicFile",
            desc: t("forms:driver.publicSection.profilePic"),
          },
          {
            required: settings?.driverRequirementLicenseFiles ? true : false,
            count: 4,
            originalName: "licenseFiles",
            name: "newLicenseFiles",
            desc: t("forms:driver.licenseSection.licenseLabel"),
          },
          {
            required: (settings?.driverRequirementIdentityFiles && ENABLE_CC) ? true : false,
            count: 1,
            originalName: "identityFrontFile",
            name: "newIdentityFrontFile",
            desc: t("forms:driver.verificationSection.poiLabel"),
          },
          {
            required: (settings?.driverRequirementIdentityFiles && ENABLE_CC) ? true : false,
            count: 1,
            originalName: "identityBackFile",
            name: "newIdentityBackFile",
            desc: t("forms:driver.verificationSection.poiLabel"),
          },
          {
            required: settings?.driverRequirementPoliceFiles ? true : false,
            count: 4,
            originalName: "policeFiles",
            name: "newPoliceFiles",
            desc: t("forms:driver.verificationSection.policeCheckLabel"),
          },
          {
            required: settings?.driverRequirementHistoryFiles ? true : false,
            count: 4,
            originalName: "historyFiles",
            name: "newHistoryFiles",
            desc: t("forms:driver.verificationSection.drivingHistoryLabel"),
          },
          {
            required: settings?.driverRequirementVevoFiles ? true : false,
            count: 4,
            originalName: "vevoFiles",
            name: "newVevoFiles",
            desc: t("forms:driver.verificationSection.vevoDescription"),
          },
          {
            required: settings?.driverRequirementMedicareCardFiles ? true : false,
            count: 4,
            originalName: "medicareFiles",
            name: "newMedicareFiles",
            desc: t("forms:driver.verificationSection.medicareDescription"),
          },
          {
            required: settings?.driverRequirementEnglishLanguageProficiencyFiles ? true : false,
            count: 4,
            originalName: "proficiencyFiles",
            name: "newProficiencyFiles",
            desc: t("forms:driver.verificationSection.proficiencyDescription"),
          },
        ]}>
        {style === FormStyle.Containerized && header()}
        <SectionsWrapper
          className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
          {style !== FormStyle.Containerized && (
            <FormHeader
              type={FormHeaderType.Title}
              title={t("entities.driver")}
            />
          )}
          <FormSections>
            {(settings.driverRequirementFeatures) && (entityCount.featureCount < 1 && formMode == 0) ? <div className="alert alert-danger" role="alert">
              Please Add Feature Before Adding A New Driver
            </div> : ""}
            {(settings.driverRequirementFleets) && (entityCount.fleetCount < 1 && formMode == 0) ? <div className="alert alert-danger" role="alert">
              Please Add Fleet Before Adding A New Driver
            </div> : ""}
            {(settings.driverRequirementNetworks) && (entityCount.networkCount < 1 && formMode == 0) ? <div className="alert alert-danger" role="alert">
              Please Add Network Before Adding A New Driver
            </div> : ""}
            {(settings.driverRequirementOperators) && (entityCount.operatorCount < 1 && formMode == 0) ? <div className="alert alert-danger" role="alert">
              Please Add Operator Before Adding A New Driver
            </div> : ""}
            {(settings.driverRequirementGroups) && (entityCount.groupCount < 1 && formMode == 0) ? <div className="alert alert-danger" role="alert">
              Please Add Group Before Adding A New Driver
            </div> : ""}
            <urlShow.Provider value={pic}>
              <DriverPostPublic FormEditingOnclick={FormEditingOnclick} settings={settings} />
            </urlShow.Provider>
            <DriverPostPrivate onChange={setValue} value={value} settings={settings} />
            <DriverPostEmergency onChange={setEmergencyPhoneValue} value={emergencyPhoneValue} settings={settings} />
            <DriverPostLicense settings={settings} />
            {process.env.REACT_APP_NAME == Enviroment.Poles ? null :
              <DriverPostBank settings={settings} />}
          </FormSections>
        </SectionsWrapper>

        <SectionsWrapper
          className={style === FormStyle.Containerized ? "col-lg-12" : ""}
        >
          {style !== FormStyle.Containerized && (
            <FormHeader type={FormHeaderType.Controls} />
          )}
          <FormSections>
            <DriverPostVerification settings={settings} />
            <DriverPostFleet settings={settings} />
            <DriverPostNetwork settings={settings} />
            <DriverPostOperator settings={settings} />
            <DriverPostGroup settings={settings} />
            <DriverPostFeature settings={settings} />
          </FormSections>
        </SectionsWrapper>
      </Form> : null
  )
}

import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { FormInput } from '../../../components/form/input/input.component';
import { Currency } from '../../../entities/currency.entity';
import { Settings } from '../../../entities/settings.entity';
import { Transaction } from '../../../entities/transaction.entity';
import { Zone } from '../../../entities/zone.entity';
import { getDatesForFilters, StatsFilterPeriod } from "../../../interfaces/stats.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { SettingsService } from '../../../services/settings.service';
import { dateToISOWithOffset } from "../../../util/function.util";
interface Sort {
  sortDirection: string | undefined,
  accessor: string | undefined
}

export interface transactionReport {
  transaction: Transaction[]
  count: number
}


export function BalanceSheet() {
  const [transaction, setTransaction] = useState<any>(undefined);
  const [transactionCSV, setTransactionCSV] = useState<any>("");
  const [from, setFrom] = useState<string>('');
  const { t } = useTranslation('dashboard');
  const translationReport = useTranslation('main')
  const formProps = useFormContext();
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);
  const [to, setTo] = useState<string>('');
  const [pageCounts, setPageCounts] = useState<number>(1);
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState<string>('')
  const [itemCount, setItemCount] = useState<number>(0)
  const [sort, setSort] = useState<Sort>({ sortDirection: undefined, accessor: undefined });
  const [user, setUser] = useState(AuthService.getUser());
  const [columnData, setColumnData] = useState([]);
  const history = useHistory();
  const [intervalId, setIntervalId] = useState<any>();
  const [leftScrollIntervalId, setLeftScrollIntervalId] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true)
  const [zoneData, setZoneData] = useState<Zone>();
  const [recordCount, setRecordCount] = useState<number>(0)

  const ref = useRef<any>(null);
  let selectedZone: Zone;
  useLayoutEffect(() => {
    getZone()
  }, [transaction, to, from, time])

  useEffect(() => {

    if (!columnData) {

      history.push("/dashboard/overview")

    }

  }, [columnData])

  const getZone = async () => {
    const zone = await Api.get<any, any>(Endpoint.ZONE_SELECTED, {})
    selectedZone = zone
    setZoneData(zone)

  }

  const toSubUnit = (value: number): any => {
    const unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
    // const result = value/unit
    const fixed = Math.round((Math.log(unit) / Math.log(10)));
    const result = (value / unit).toFixed(fixed)
    return result;
  }

  let sum = 0;

  const refresh = useCallback(async (limit: number, offset: number, search?: string, direction?: string, column?: string, total?: any) => {
    setLoading(true);
    const settings: Settings | undefined = SettingsService.getSetting();
    const unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
    if (column && search) {
      let number;
      // for amount 
      if (column === ('Amount')) {
        number = search.split(" ", 2)
        const cashValue = parseInt(number[1]) * unit
        search = `${number[0]} ${cashValue}`
      }
    }
    let trans: transactionReport | undefined = undefined;
    let startDate: Date, endDate: Date;
    if (time == StatsFilterPeriod.Custom) {
      startDate = from ? new Date(from) : new Date(); endDate = to ? new Date(to) : new Date();
    }
    else ({ startDate, endDate } = getDatesForFilters(time));

    trans = await Api.get<transactionReport, any>(Endpoint.TRANSACTION_LIST, {
      limit, offset, search, column, direction, startDate: dateToISOWithOffset(startDate, "start"), endDate: dateToISOWithOffset(endDate, "end")
    });

    const temp = Math.ceil(trans?.count as number / pageSize)
    setPageCounts(temp)
    setRecordCount(trans?.count as number)
    const currency = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).symbol : null;
    let ro: any = [];
    if (trans?.transaction && trans?.transaction.length > 0) {
      trans?.transaction.map((key: any) => {
        sum = sum + key.amount
        ro.push({
          'TripCode': key.trip?.code == null ? "" : key.trip.code,
          'CreateTime': new Date(key?.createTime).toLocaleString(),
          'TransactionID': key?.shortId == null ? "" : key.shortId,
          'Provider': key?.provider == null ? "" : key.provider,
          'NetworkOperator': key.trip?.session?.driver?.networks[0]?.title == null ? "" : key.trip.session.driver?.networks[0].title,
          'Operator': key.trip?.session?.driver?.operators[0]?.title == null ? "" : key.trip.session.driver?.operators[0].title,
          'Fleet': key.trip?.session?.driver?.fleets[0]?.title == null ? "" : key.trip.session.driver.fleets[0].title,
          "Service": key.trip?.service?.title == null ? "" : key.trip.service.title,
          'Zone': key.trip?.zone?.title == null ? "" : key.trip.zone.title,
          'Fare': key.trip?.fare?.title == null ? "" : key.trip.fare.title,
          'Driver': key.trip?.session == null ? "" : `${key.trip?.session?.driver?.firstName} ${key.trip.session.driver.lastName}`,
          'Passenger': key.trip?.passenger?.firstName == undefined ? "" : `${key.trip?.passenger?.firstName} ${key.trip?.passenger?.lastName}`,
          'Timestamp': new Date(key.createTime).toLocaleString(),
          'Amount': key?.amount == null ? `0` : `${currency} ${toSubUnit(key.amount)}`,

        });

      })
      ro = [...ro,
      {
        'TripCode': "",
        'CreateTime': "",
        'TransactionID': "",
        'Provider': "",
        'NetworkOperator': "",
        'Operator': "",
        'Fleet': "",
        "Service": "",
        'Zone': "",
        'Fare': "",
        'Driver': "",
        'Passenger': "",
        'Timestamp': "",
        'Amount': <><strong>Sub Total : </strong>{currency} {toSubUnit(sum)}</>,
      },
      {
        'TripCode': "",
        'CreateTime': "",
        'TransactionID': "",
        'Provider': "",

        'NetworkOperator': "",
        'Operator': "",
        'Fleet': "",
        "Service": "",
        'Zone': "",
        'Fare': "",
        'Driver': "",
        'Passenger': "",
        'Timestamp': "",
        'Amount': <>{total ? <><strong>Total : </strong>{currency} {toSubUnit(total)}</> : <><strong>Total : </strong>{toSubUnit(total)} {currency}</>}</>,
      }

      ]
    }
    setTransaction(ro)
    setLoading(false)

    console.log('transaction Data:', ro)
  }, [transaction, time])

  const data = React.useMemo(
    () => {
      return transaction ? [...transaction] : []
    },
    [transaction]
  )

  const columns: Array<Column<any>> = React.useMemo(
    () => {
      console.log("col data", columnData)
      const col: any = [];
      columnData?.map((column: any) => {

        col.push({
          Header: column,
          accessor: column.replaceAll(" ", ""),
          sortType: 'basic',
          sortDirection: sort.accessor === column.replaceAll(" ", "") ? sort.sortDirection : undefined

        })

      })

      return col
    }

    , [columnData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = useTable({
    columns, data, manualPagination: true, manualSortBy: true, manualFilters: true
  }, useFilters, useSortBy, usePagination)

  const { pageSize } = state

  useEffect(() => {
    getColumnData()

  }, [])

  const getColumnData = async () => {

    const userId = user?.user.id
    const columnData = await Api.get<any, any>(Endpoint.BalanceSheet, { id: userId });
    console.log("column Data of Balance Sheet", columnData);
    setColumnData(columnData[0]?.columns)
    setLoading(true)

  }

  const getPageCount = async () => {
    let startDate: Date, endDate: Date;
    if (time == StatsFilterPeriod.Custom) {
      startDate = from ? new Date(from): new Date(); endDate = to ? new Date(to): new Date();
    }
    else ({ startDate, endDate } = getDatesForFilters(time));
    setLoading(true);
    const data = await Api.get<any, any>(Endpoint.TRANSACTION_SUM, { time, from: startDate, to: endDate });
    const tempOffset = count * pageSize
    refresh(pageSize, tempOffset, search, sort.sortDirection, sort.accessor, data)
  }

  const handler = useCallback(debounce((text: string) => {
    setSearch(text)
    setLoading(true)

  }, 300), []);

  useEffect(() => {
    getPageCount()

  }, [search, pageSize, count, sort, time])

  const onNextPage = () => {
    setCount(prevCount => prevCount + 1)
  }

  const onPreviousPage = () => {
    setCount(prevCount => prevCount - 1)
  }

  const onNextNextPage = () => {
    setCount(pageCounts - 1)
  }

  const onPreviousPreviousPage = () => {
    setCount(0)
  }

  const onClickHeader = (column: any) => {
    switch (column.sortDirection) {
      case undefined:
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id, });
        break;
      case 'ASC':
        column.sortDirection = 'DESC'
        setSort({ sortDirection: 'DESC', accessor: column.id });
        break;
      case 'DESC':
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id });
        break;
    }
  }

  const onChangeSeaech = (e: any, column: any) => {
    handler(e.target.value)
  }

  const scroll = (scrollOffset: any) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const handleRightScroll = () => {

    setIntervalId(
      setInterval(() => {
        scroll(100)
      }, 50)
    )
  }

  const handleLeftScroll = () => {

    setLeftScrollIntervalId(
      setInterval(() => {
        scroll(-100)
      }, 50)
    )
  }

  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }

  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  useEffect(() => {
    handleCSV()
  }, [search, time])

  async function handleCSV() {
    let startDate: Date, endDate: Date;
    if (time == StatsFilterPeriod.Custom) {
      startDate = from ? new Date(from): new Date(); endDate = to ? new Date(to): new Date();
    }
    else ({ startDate, endDate } = getDatesForFilters(time))
    const CSVData = await Api.get<transactionReport, any>(Endpoint.TRANSACTION_LIST, { 
      limit: 999, search: search, column: sort.accessor, direction: sort.sortDirection, 
      startDate: dateToISOWithOffset(startDate, "start"), endDate: dateToISOWithOffset(endDate, "end")
    });
    let ro: any = [];
    const currency = zoneData?.generalCurrency ? (zoneData?.generalCurrency as Currency).symbol : null;
    if (CSVData && CSVData?.transaction && CSVData?.transaction.length > 0) {
      CSVData?.transaction.map((key: any) => {
        ro.push({
          'TripCode': key.trip?.code == null ? "" : key.trip.code,
          'CreateTime': new Date(key?.createTime).toLocaleString(),
          'TransactionID': key?.shortId == null ? "" : key.shortId,
          'Provider': key?.provider == null ? "" : key.provider,
          'NetworkOperator': key.trip?.session?.driver?.networks[0]?.title == null ? "" : key.trip.session.driver?.networks[0].title,
          'Operator': key.trip?.session?.driver?.operators[0]?.title == null ? "" : key.trip.session.driver?.operators[0].title,
          'Fleet': key.trip?.session?.driver?.fleets[0]?.title == null ? "" : key.trip.session.driver.fleets[0].title,
          "Service": key.trip?.service?.title == null ? "" : key.trip.service.title,
          'Zone': key.trip?.zone?.title == null ? "" : key.trip.zone.title,
          'Fare': key.trip?.fare?.title == null ? "" : key.trip.fare.title,
          'Driver': key.trip?.session == null ? "" : `${key.trip?.session?.driver?.firstName} ${key.trip.session.driver.lastName}`,
          'Passenger': key.trip?.passenger?.firstName == undefined ? "" : `${key.trip?.passenger?.firstName} ${key.trip?.passenger?.lastName}`,
          'Timestamp': new Date(key.createTime).toLocaleString(),
          'Amount': key?.amount == null ? `0` : `${currency} ${toSubUnit(key.amount)}`,

        });
      })
      setTransactionCSV([...ro])
    }

  }

  return (
    <>
      <div>
        <div className=" py text-center" style={{ display: 'flow-root' }}>
          {transaction ? <CSVLink style={{ float: "right", marginTop: "26px", marginRight: "20px" }} filename={"transaction-report.csv"} className="btn btn-primary" data={transactionCSV}>{translationReport.t('reports.exportCSV')}</CSVLink> : null}
          <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => { scroll(100) }} onMouseDown={handleRightScroll} onMouseUp={() => clearInterval(intervalId)} onMouseLeave={() => clearInterval(intervalId)}>{'>'}</button>
          <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => { scroll(-100) }} onMouseDown={handleLeftScroll} onMouseUp={() => clearInterval(leftScrollIntervalId)} onMouseLeave={() => clearInterval(leftScrollIntervalId)}>{'<'}</button>
          {loading == true ? <button disabled onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary`}>
            Loading.....</button> : <button onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary os-icon os-icon-refresh-ccw`}>
          </button>}

        </div>
        <div> {time === StatsFilterPeriod.Custom ?
          <>
            <div className="d-flex flex-row align-items-center">
              <FormInput
                label={t("From")}
                name="from"
                type="date"
                onChange={onChangeFrom}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
              <FormInput
                label={t("To")}
                name="to"
                type="date"
                onChange={onChangeTo}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
              <div onClick={() => {
                //@ts-ignore
                if (!from) { alert('Please select start range!'); return; }
                else {
                  if (!to) setTo(from);
                  refresh(pageSize, count * pageSize);
                  // props.onCustomSelect(new Date(START), new Date(!END ? START : END));
                }
              }} className='btn btn-outline-primary w-25 align-self-center p-2 mb-0 mt-2'>
                <p className='fs-5 m-0'>GET</p>
              </div>
            </div>

          </> : null}
          <div style={{ marginRight: '15px', marginBottom: '10px', marginTop: '15px' }}>
            <form className="form-inline justify-content-sm-end">
              <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
                <option value={StatsFilterPeriod.Today}>{t('dropdown.today')}</option>
                <option value={StatsFilterPeriod.Yesterday}>{t('dropdown.yesterday')}</option>
                <option value={StatsFilterPeriod.ThisWeek}>{t('dropdown.thisWeek')}</option>
                <option value={StatsFilterPeriod.LastWeek}>{t('dropdown.lastWeek')}</option>
                <option value={StatsFilterPeriod.ThisMonth}>{t('dropdown.thisMonth')}</option>
                <option value={StatsFilterPeriod.LastMonth}>{t('dropdown.lastMonth')}</option>
                <option value={StatsFilterPeriod.ThisYear}>{t('dropdown.thisYear')}</option>
                <option value={StatsFilterPeriod.Custom}>{t('dropdown.custome')}</option>
              </select>
            </form>
          </div> </div>
        <div ref={ref} style={{ marginLeft: "5px", marginRight: "5px", overflowX: "scroll", width: "100%" }} className=" py-5 text-center r2">

          <table {...getTableProps()} className="table table-striped table-Info table-hover table-bordered">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => {
                        onClickHeader(column)
                      }} className="bg-primary text-white">
                      {column.render('Header') ? translationReport.t(`reports.transaction.${column.id}`) : ""

                      }
                      <span>
                        {column.sortDirection === 'ASC' ? (
                          ' 🔽'
                        ) : column.sortDirection === 'DESC' ? (
                          ' 🔼'
                        ) : null}
                      </span>
                      <div><span>
                        <input onChange={(e) => onChangeSeaech(e, column)}
                        />
                      </span></div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {transaction?.length > 0 ? <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody> : <tbody>
              <tr >
                <td colSpan={columnData.length}>
                  No results to display
                </td>
              </tr>
            </tbody>}
          </table>
        </div>
        <div className=" py-5 text-center">
          <span>
            {translationReport.t('reports.page')}{' '}
            <strong>
              {count + 1} {translationReport.t("reports.of")} {pageCounts}
            </strong>{' '}
          </span>
          <span>
            {' | '}{translationReport.t('Records')}{' '}
            <strong>
              {recordCount}
            </strong>{' '}
          </span>
          <span>
            | {translationReport.t("reports.goPage")}{''}
            <input
              type='number'
              defaultValue={count + 1}
              onChange={(e: any) => {
                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                setCount(pageNumber)
              }}
              style={{ marginRight: "10px", width: "70px", marginLeft: "5px", borderRadius: "5px" }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e: any) => setPageSize(Number(e.target.value))}
            style={{ marginRight: "10px", width: "80px", marginLeft: "5px", borderRadius: "5px" }}
          >
            {[10, 25, 50, 100].map((pageSize: any) => {
              return <option key={pageSize} value={pageSize}>
                {translationReport.t('reports.show')} {pageSize}
              </option>
            })}
          </select>
          <button type="button" className="btn btn-primary" onClick={() => onPreviousPreviousPage()} disabled={count > 0 ? false : true}>{'<<'}</button>
          <button type="button" style={{ marginRight: "10px", marginLeft: "10px" }} className="btn btn-primary" onClick={() => onPreviousPage()} disabled={count > 0 ? false : true}>{translationReport.t('reports.previous')}</button>
          <button type="button" style={{ marginRight: "10px" }} className="btn btn-primary" onClick={() => onNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{translationReport.t('reports.next')}</button>
          <button type="button" className="btn btn-primary" onClick={() => onNextNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{'>>'}</button>
        </div>
      </div>
    </>
  )
}

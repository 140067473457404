// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/messaging';
import firebase from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjPn9AvogpVvF5Hxk2kzFI6Kpo8vZQhpI",
  authDomain: "coolride-314817.firebaseapp.com",
  projectId: "coolride-314817",
  storageBucket: "coolride-314817.appspot.com",
  messagingSenderId: "487028904340",
  appId: "1:487028904340:web:8a30d3fe4bad2338087f81",
  measurementId: "G-JL4N9GN72Z"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);




export default firebase
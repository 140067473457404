import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../components/form/input/input.component';
import { FormRadio, RadioOption } from '../../../components/form/input/radio.component';
import { FormSelect } from '../../../components/form/input/select.component';
import { FormSection } from '../../../components/form/section.component';
import { Currency } from '../../../entities/currency.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import Timezones from '../../settings/timezone.data';

const timezones = Timezones.map(tz => ({ id: tz, name: tz }))

interface PaymentMethod {
  id: string;
  name: string;
}

export const paymentMethods = [
  { name: 'Cash', id: 'cash' },
  { name: 'App', id: 'app' },
  { name: 'Credit Card', id: 'credit-card' },
] as PaymentMethod[];

export function PaymentSection() {
  const { t } = useTranslation('forms');
  const translationSettings = useTranslation("settings")
  const childProps = useFormContext();
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [creditType, setCreditType] = useState<any>([
    { label: translationSettings.t("payment.creditCardSection.typeFixed"), value: 'fixed' },
    { label: translationSettings.t("payment.creditCardSection.typePercentage"), value: 'percentage', selected: true },
  ]);
  const [ccCharge, setCCCharge] = useState<any>();

  useEffect(() => {
    const data = childProps.getValues()
    const data1 = creditType.map((cur: any) => {

      if (cur.value == data.paymentCCChargeType) {
        return {
          selected: true,
          label: cur.label,
          value: cur.value,
        }
      }
      else {
        return {
          // selected: response?.generalCurrency?.id,
          label: cur.label,
          value: cur.value,
        }
      }
    })
    console.log("data 1",data1)
    setCCCharge(data1)
  }, [])

  const refreshCurrencies = useCallback((async () => {
    const { items } = await Api.get<IPaginationResponse<Currency>, IPaginationOptions>(Endpoint.SETTINGS_CURRENCIES_LIST, { page: 0 });
    setCurrencies(items);
  }), [])

  useEffect(() => {
    refreshCurrencies();
  }, [refreshCurrencies])

  const toCurrencyOption = (currency: Currency): RadioOption => {
    const form = childProps.getValues();

    return {
      selected: currency.id === (form.generalCurrency),
      label: currency.code,
      value: currency.id as string,
    }
  }

  return (
    <FormSection
      title={t("zone.meter.taxSection.paymentMethods")}
      subtitle={translationSettings.t("payment.methodsSection.subTitle")} 
    >
      <FormSelect<PaymentMethod>
        label={t("zone.meter.taxSection.paymentMethods")}
        name="paymentMethods"
        className="col-sm-12" 
        multiple={true}
        validation={{ required: true }}
        items={paymentMethods}
        tabIndex={14}
        {...childProps}
      />
      <FormRadio
        label={t("zone.meter.taxSection.paymentCCType")}
        name="paymentCCChargeType"
        className="col-sm-12"
        validation={{ required: true }}
        {...childProps}
        onChange={
          (e: any) => {
            const data = creditType.map((cur: any) => {
              if (cur.value == e.target.value) {
                return {
                  selected: true,
                  label: cur.label,
                  value: cur.value,
                }
              }
              else {
                return {
                  label: cur.label,
                  value: cur.value,
                }
              }
            })
            setCCCharge(data)
          }}
        options={ccCharge ? ccCharge : []}
        tabIndex={15}
      />
      <FormInput
        name="paymentCCChargeValue"
        label={t("zone.meter.taxSection.paymentCCValue")}
        placeholder=""
        type="number"
        step={0.01}
        className="col-sm-12"
        tabIndex={15}
        validation={{ required: true }}
        {...childProps}
      />
    </FormSection>






  );
}
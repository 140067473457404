import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-responsive-modal';
import { StatsComponent } from '../../../components/stats/stats.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentFiles } from '../../../components/view/files.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Currency } from '../../../entities/currency.entity';
import { Device } from '../../../entities/device.entity';
import { Driver, DriverStatus, StripeStatus } from '../../../entities/driver.entity';
import { DriverCheckList } from '../../../entities/driverCheckList.entity';
import { IFile } from '../../../entities/file.entity';
import { Inspection, InspectionType } from "../../../entities/inspection.entity";
import { InspectionAssignment, InspectionAssignmentStatus } from '../../../entities/inspectionAssignment.entity';
import { Role, RoleAssignment } from '../../../entities/role.entity';
import { Settings } from '../../../entities/settings.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsResponse } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { SettingsService } from '../../../services/settings.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';
import { getStorageUrl } from '../../../util/file.util';
import { capitalize } from '../../../util/string.util';
import { Checklist } from './checklist.component';
import { ProfilePicSize } from './list.component';

enum ApproveButtonStatus {
    APPROVE = "approve",
    CONFIRM = "confirm",
    APPROVING = "approving"

}
interface Props {
    selectedDriver: Driver;
    getFullName: (driver: Driver) => string;
    getProfilePic: (driver: Driver, size: ProfilePicSize) => string;
    onApprove?: () => void,
    setSelectedDriver?: (driver: undefined | Driver,) => void
}
export function SelectedItemContent({ selectedDriver, getFullName, getProfilePic, onApprove, setSelectedDriver }: Props) {
    const { t } = useTranslation("forms");
    const translation = useTranslation("dashboard");

    const [driver, setDriver] = useState<Driver>(selectedDriver);
    const [stats, setStats] = useState<StatsResponse>();
    const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);
    const [deviceCode, setDeviceCode] = useState<string>();
    const [vehicleCode, setVehicleCode] = useState<string>();
    const [deviceAuthCode, setDeviceAuthCode] = useState<number>();
    const [error, setError] = useState<string>();
    const [depositAccess, setDepositAccess] = useState<boolean>();

    const [balanceAccess, setBalanceAccess] = useState<boolean>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [vehicleError, setVehicleError] = useState<string>();
    const [inspection, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);
    const [approveStatus, setApproveStatus] = useState<ApproveButtonStatus>(ApproveButtonStatus.APPROVE);
    const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);
    const [symbol, setSymbol] = useState<string | null>('')
    const [subUnit, setSubUnit] = useState<number>(100)
    const [amount, setAmount] = useState<number>(0)
    const [paymentType, SetPaymentType] = useState<string>("cash");
    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);
    const [approveMessage, setApproveMessage] = useState([])
    // const [identityfiles,setIdentityFiles]  = useState<any>([]);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const payexternal = async () => {
        const { items } = await Api.get<any, any>(Endpoint.DRIVER_PAY, { id: selectedDriver?.id });
    }
    const _handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            onGetDeviceCode();
        }
    }

    const _handleKeyDownDeposit = (e: any) => {
        if (e.key === 'Enter') {
            onGetDeposit();
        }
    }

    const ref = useCallback(() => {
        const refresh = async () => {
            console.log('Inside Again Ref');
            const { items } = await Api.get<IPaginationResponse<Inspection>, IPaginationOptions>(Endpoint.INSPECTION_LIST, { page: 0, limit: 20, });
            if (items) {
                const data = items.filter(items => (items.type == InspectionType.Driver))
                setInspection(data);
                setInspectionAssignment([]);

                data?.forEach((DriverInspectionData) => {
                    let finalStatus: InspectionAssignmentStatus = InspectionAssignmentStatus.Pending;

                    setInspectionAssignment((items) => {
                        selectedDriver?.driverCheckList?.forEach((InspectionData) => {
                            console.log('This is DriverCheckList Data:', InspectionData, DriverInspectionData.title)
                            if (InspectionData.checklistItem == DriverInspectionData.title) {
                                finalStatus = InspectionAssignmentStatus.Done;
                            }
                        })
                        return ([...items, {
                            id: selectedDriver?.id,
                            inspection: DriverInspectionData,
                            entity: 'Driver',
                            status: finalStatus,
                        }])
                    })
                })
            }
            console.log('Inspection Assignment :', inspectionAssignment);
        };
        refresh();
        //   setInspectionAssignment()
    }, [selectedDriver, inspectionAssignment]);
    const {
        identityFiles,
        registrationFiles,
        insuranceFiles
    } = selectedDriver;

    const onGetDeviceCode = async () => {
        if (!deviceCode) {
            setError('Invalid Device Code');
        }
        else if (!vehicleCode) {
            setError(undefined);
            setVehicleError('Invalid Vehicle Code')
        }
        else {
            try {
                setVehicleError(undefined);
                setDeviceAuthCode(undefined)
                setError(undefined)
                console.log('vehicleCode', selectedDriver.id, vehicleCode.toUpperCase(), 'deviceCode', deviceCode.toUpperCase())
                const response: any = await Api.get<Device, { code: string, id: string, vehicleCode: string }>(Endpoint.DRIVER_Device_Get_AUTH, { code: deviceCode.toUpperCase(), id: selectedDriver.id as string, vehicleCode: vehicleCode.toUpperCase() });
                console.log('This is Auth Code:', response);
                setDeviceAuthCode(response.authCode)

            } catch (error: any) {
                setDeviceAuthCode(undefined)
                setError(error);
                console.log('Error Inside Device Auth Code:', error);
            }
        }
    }
    const onGetDeposit = async () => {
        setSuccessMessage("")
        setError("")
        if (!amount || amount==0) {
            setError("Please Enter Amount")
        }
        else {
            const response = await Api.post<any, any>(Endpoint.TRANSACTION_DEPOSIT, { driverId: selectedDriver.id as string, amount: Math.floor((amount * subUnit)), paymentType });
            console.log("response deposit", response);
            if (!response) {
                setError("Something is wrong !")
            }
            setSuccessMessage("Sucessfully Deposit")
            setAmount(0);
            init()
        }
    }
    // const onGetDeposit = async () => {
    //     if (!amount) {
    //         setError("Please Enter Amount")
    //     }
    //     else {
    //         const response = await Api.post<any, any>(Endpoint.TRANSACTION_DEPOSIT, { driverId: selectedDriver.id as string, amount: parseInt(amount), paymentType });
    //         console.log("response deposit", response);
    //         if (!response) {
    //             setError("Something is wrong !")
    //         }
    //         else {
    //             setSuccessMessage("Succesfully deposit")
    //         }

    //     }
    // }
    const init = useCallback(async () => {
        // setDriver(selectedDriver);
        const driver = await Api.get<Driver, { id: string }>(Endpoint.DRIVER, { id: selectedDriver.id as string });
        console.log('Driver:', driver, 'selectedDriver:', selectedDriver);
        setDriver(driver);
        setApproveMessage(driver?.stripeMessage)
    }, [selectedDriver]);
    const toSubUnit = (value: number): number => {
        const settings: Settings | undefined = SettingsService.getSetting();
        // const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
        const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
        return value / unit;
    }
    const newVal = async (i: InspectionAssignment) => {
        console.log('Inside New Value Change,', i);
        if (i?.status === InspectionAssignmentStatus.Done) {
            if (i.inspection) {
                console.log('Inside inspection', i);
                const driverCheckListData: DriverCheckList = { driver: selectedDriver?.id as string, checklistItem: i.inspection.title, checked: true }
                selectedDriver.driverCheckList.push(driverCheckListData);
                //create aa seprate endpoint for driver driverCheckList Data
                await Api.patch(Endpoint.DRIVER, { id: selectedDriver.id, driverCheckList: selectedDriver.driverCheckList });
            }
        }
        else if (i.status === InspectionAssignmentStatus.Pending) {
            selectedDriver.driverCheckList = selectedDriver.driverCheckList.filter(item => item.checklistItem !== i.inspection?.title)
            await Api.patch(Endpoint.DRIVER, { id: selectedDriver.id, driverCheckList: selectedDriver.driverCheckList });
        }
        ref();
    }
    const getLocaleDateOfBirth = (driver: Driver | undefined): string => {
        return driver && driver.dateOfBirth ? new Date(driver.dateOfBirth).toLocaleDateString() : '';
    }
    const getLocaleDateOfConsent = (driver: Driver | undefined): string => {
        return driver && driver.createTime ? new Date(driver.createTime).toLocaleDateString() : '';
    }
    const getFileSrc = (file: IFile): { thumb: string, full: string } => {
        const files = {
            thumb: `${getStorageUrl()}/driver/${driver?.id}/${file.type}/${file.id}/admin_single.${file.extension}`,
            full: `${getStorageUrl()}/driver/${driver?.id}/${file.type}/${file.id}/admin_large.${file.extension}`,
        }
        return files
    }
    const approve = async (driver: Driver) => {
        if (!confirmingApproval) {
            setConfirmingApproval(true);
            return;
        }

        setConfirmingApproval(false);

        if (driver.status === DriverStatus.Active || driver.status === DriverStatus.Blocked) {
            setConfirmingApproval(false);
            return;
        }

        let status = driver.status === DriverStatus.New ? DriverStatus.PendingData : DriverStatus.PendingData ? DriverStatus.PendingApproval : DriverStatus.PendingApproval ? DriverStatus.Active : null;
        if (driver.status === DriverStatus.PendingApproval) {
            status = DriverStatus.Active;
        }
        try {
            await Api.patch(Endpoint.DRIVER, { id: driver.id, status });
            if (status === DriverStatus.Active) {
                setApproved(true);
            }
            if (onApprove) {
                onApprove();
            }
        } catch (err) {
            console.error(err);
        }
    }
    const onclickRecover = async (selectedDriver: Driver) => {
        console.log('Recover This Driver:', selectedDriver);
        const response = await Api.post<Driver, { email: string }>(Endpoint.DRIVER_RECOVER, { email: selectedDriver.email as string });
        console.log('Recover This Driver Response:', response)
        if (onApprove && setSelectedDriver) {
            setSelectedDriver(undefined);
            onApprove();
        }
    }
    const renderApprovalMessage = (driver: Driver): ReactNode => {
        if (driver.deletedTime) {
            return (
                <div className="alert alert-success borderless">
                    <h5 className="alert-heading">Driver Recovery</h5>
                    <p>Do you want to recover this driver account?</p>
                    <div className="alert-btn">
                        <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedDriver)}>
                            <i className="os-icon os-icon-ui-92"></i>
                            <span>{'Recover Account'}</span>
                        </a>
                    </div>
                </div>
            );
        }
        if (approved) {
            return (
                <div className="alert alert-success borderless">
                    <h5 className="alert-heading">Driver Approved</h5>
                    <p>Driver has been successfully approved.</p>
                </div>
            );
        }

        switch (driver.status) {
            case DriverStatus.New:
                return (
                    <div className="alert alert-warning borderless">
                        <h5 className="alert-heading">Driver awaiting initial approval</h5>
                        <p>This driver has just submitted their request to register as a drover. Once you approve them, they will be asked to fill in their full details.</p>
                        <div className="alert-btn">
                            <a className="btn btn-white-gold" onClick={() => approve(selectedDriver)}>
                                <i className="os-icon os-icon-ui-92"></i>
                                <span>{confirmingApproval ? 'Confirm Approval' : 'Approve'}</span>
                            </a>
                        </div>
                    </div>
                );
            case DriverStatus.PendingData:
                return (
                    <div className="alert alert-warning borderless">
                        <h5 className="alert-heading">Driver awaiting details</h5>
                        <p>This driver has received an initial approval and needs to fill in their details. Once the details have been vetted, you need to give them the final approval.</p>
                    </div>
                );
            case DriverStatus.PendingApproval:
                return (
                    <div className="alert alert-warning borderless">
                        <h5 className="alert-heading">Driver awaiting final approval</h5>
                        <p>This driver has received an initial approval and has filled our their details. Once the details have been vetted, you need to give them the final approval.</p>
                        <div className="alert-btn">

                            {approveStatus == ApproveButtonStatus.APPROVE ? <a className="btn btn-white-gold" href={'#href'} onClick={() => {
                                setApproveStatus(ApproveButtonStatus.CONFIRM)
                                approve(selectedDriver)
                            }}>
                                <i className="os-icon os-icon-ui-92"></i>
                                <span>Approve</span>
                            </a> : ""}

                            {approveStatus == ApproveButtonStatus.CONFIRM ? <button className="btn btn-white-gold" onClick={() => {
                                setApproveStatus(ApproveButtonStatus.APPROVING)
                                approve(selectedDriver)
                            }}>
                                <i className="os-icon os-icon-ui-92"></i>
                                <span>{confirmingApproval ? 'Confirm Approval' : 'Approving.....'}</span>
                            </button> : ""}


                            {approveStatus == ApproveButtonStatus.APPROVING ?
                                <button disabled className="btn btn-white-gold">
                                    <i className="os-icon os-icon-ui-92"></i>
                                    <span>Approving.....</span>
                                </button> : ""}



                        </div>
                    </div>
                );
        }

        return null;
    }
    const changeTime = (e: any) => {
        setTime(e.target.value)
    }
    const changePayment = (e: any) => {
        SetPaymentType(e.target.value)
    }
    const refreshStats = useCallback(async (customStart?: Date, customEnd?: Date) => {
        let startDate: Date, endDate: Date, timeline = time;
        if (customStart && customEnd) {
            startDate = customStart; endDate = customEnd; timeline = StatsFilterPeriod.Custom;
        }
        else {
            ({ startDate, endDate } = getDatesForFilters(time)); timeline = time;
        }
        const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
            startDate, endDate, timeline, driver: driver.id
        });
        console.log('stats:', response)
        setStats(response);
    }, [time]);

    useEffect(() => {
        const Payload: any | undefined = AuthService.getUser();
        const { user } = Payload;
        const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
        const moduleCodes = Array.from(assignments, assignment => ({
            module: assignment.module,
            action: assignment.action
        }));
        const newCodes = moduleCodes.filter((item: any) => {
            return item.module == "driver" && item.action == "deposit"
        })
        if (newCodes?.length > 0) {
            setDepositAccess(true)
        }
        else {
            setDepositAccess(false)
        }

        const balance = moduleCodes.filter((item: any) => {
            return item.module == "driver" && item.action == "view-balance"
        })
        if (balance?.length > 0) {
            setBalanceAccess(true)
        }
        else {
            setBalanceAccess(false)
        }
        console.log("module Codes", newCodes);
        const settings: Settings | undefined = SettingsService.getSetting();
        const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
        const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
        setSymbol(currency)
        setSubUnit(unit)
    }, []);
    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedDriver]);
    useEffect(() => {
        refreshStats();
    }, [refreshStats]);
    useEffect(() => {
        init();
        ref();
    }, [selectedDriver, init]);
    function onCustomSelect(startDate: Date, endDate: Date) {
        refreshStats(startDate, endDate);
    }

    return (
        <ContentWrapper className="row">
            <ViewContent
                componentName='driver'
                className="col-md-6"
                title={getFullName(selectedDriver)}
                subtitle={selectedDriver.code}
                imgSrc={getProfilePic(selectedDriver, ProfilePicSize.Single)}
                selectedId={selectedDriver.id!}
            >
                <ViewContentItems>
                    {driver.stripeStatus == StripeStatus.Active ? renderApprovalMessage(selectedDriver) : driver.stripeStatus == StripeStatus.Overdue ? <div className="alert alert-danger borderless">
                        {approveMessage?.length > 0 ?
                            <>
                                <ul>
                                    {approveMessage.map((item: any, index: any) => { return <li key={index}>{item.reason}</li> }
                                    )}

                                </ul>
                            </>
                            : ""}
                        {/* <p>{t('driver.approve.title')}</p> */}
                    </div> :
                        <div className="alert alert-danger borderless">
                            <h5 className="alert-heading">{t('driver.approve.verification')}</h5>
                            {approveMessage?.length > 0 ?
                                <>
                                    <h5 style={{ textAlign: "center", marginTop: "25px" }} className="alert-heading">{t('Application Rejected')}</h5>
                                    <ul>
                                        {approveMessage.map((item: any, index: any) => { return <li key={index}>{item.reason}</li> }
                                        )}

                                    </ul>
                                </>
                                : ""}
                            {/* <p>{t('driver.approve.title')}</p> */}
                        </div>
                    }
                    <ViewContentItem title={t('common.email')}>
                        <a href={`mailto:${driver?.email}`}>{driver?.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t('common.phone')}>
                        <a href={`tel:${driver?.phone}`}>{driver?.phone}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t('common.gender')}>
                        {capitalize(driver?.gender)}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.dob')}>
                        {getLocaleDateOfBirth(driver)}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.homeAddress')}>
                        {driver?.address?.text}
                    </ViewContentItem>

                    { process.env.REACT_APP_BUSSINESS_REGISTRATION_LABEL && 
                        <ViewContentItem title={`${process.env.REACT_APP_BUSSINESS_REGISTRATION_LABEL}`}>
                            {driver?.businessRegistration}
                        </ViewContentItem>
                    }

                    <ViewContentItem title={t('common.fleet')}>
                        {driver?.fleets?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.networks')}>
                        {driver?.networks?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.operators')}>
                        {driver?.operators?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.groups')}>
                        {driver?.groupDrivers?.map((n: any) => n.group.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.consent')}>
                        {driver?.consent ? <div style={{ color: "#047bf8" }} onClick={onOpenModal}>Version {driver?.consent.version} ({getLocaleDateOfConsent(driver)})</div> : null}
                        <Modal open={open} onClose={onCloseModal} center>
                            <h2 style={{ marginTop: '20px' }}>Driver Agreement</h2>
                            <p>{driver?.consent?.driverAgreement}</p>
                            <h2 style={{ marginTop: '5px' }}>Driver Use Of Information</h2>
                            <p>{driver?.consent?.driverUseOfInformation}</p>
                        </Modal>
                    </ViewContentItem>
                    <ViewContentItem title={t('common.features')}>
                        {driver?.features?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>
                    <ViewContentItem title={t('driver.emergencySection.emergencyName')}>
                        {driver?.emergencyName}
                    </ViewContentItem>
                    <ViewContentItem title={t('driver.emergencySection.emergencyPhone')}>
                        {driver?.emergencyPhone}
                    </ViewContentItem>
                    <ViewContentItem title={t('driver.emergencySection.emergencyEmail')}>
                        {driver?.emergencyEmail}
                    </ViewContentItem>
                </ViewContentItems>
            </ViewContent>
            <div className="todo-list element-box col-md-6">

                <div className="row">
                    {error && <div className="alert alert-danger btn-block" style={{ background: '#ffbebea6' }} role="alert">
                        <h6>{error}</h6>
                    </div>}
                    {successMessage && <div className="alert alert-success btn-block" style={{ background: '#b0e7b0' }} role="alert">
                        <h6>{successMessage}</h6>
                    </div>}
                    {vehicleError && <div className="alert alert-danger btn-block" style={{ background: '#ffbebea6' }} role="alert">
                        <h6>{vehicleError}</h6>
                    </div>}

                    {deviceAuthCode && <div className="alert alert-success btn-block" style={{ background: '#b6ddb1b5' }} role="alert">
                        <h6>Auth Code: {deviceAuthCode}</h6>
                    </div>}
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <input className='form-control' onKeyDown={_handleKeyDown} onChange={(e) => setDeviceCode(e.target.value)} placeholder={t('common.deviceCode')} />
                    </div>
                    <div className="col-md-4 form-group">
                        <input className='form-control' onKeyDown={_handleKeyDown} onChange={(e) => setVehicleCode(e.target.value)} placeholder={t('common.vehiclePlate')} />
                    </div>
                    <div className="col-md-4">
                        <button className='btn btn-primary' style={{ padding: '9px' }} onClick={onGetDeviceCode}>{t('common.getAuthCode')}</button>
                        {/* <button className='btn btn-primary' style={{ padding: '9px', marginTop:"5px", marginBottom:"5px" }} onClick={payexternal}>{t('common.verifyStripe')}</button> */}
                    </div>
                </div>

                {depositAccess ? <div className="row">

                    <div className="col-md-4 form-group">
                        <input type={'number'}  min="0" value={amount} className='form-control' onKeyDown={_handleKeyDownDeposit} onChange={(e) => {
                 setSuccessMessage("");
                setError("");setAmount(e.target.value as any)}} placeholder={t('common.enterAmount')} />
                    </div>
                    <div className="col-md-4 form-group">
                        {/* <input className='form-control' onChange={(e)=>setVehicleCode(e.target.value)} placeholder="Vehcile Plate"/> */}
                        <select value={paymentType} onKeyDown={_handleKeyDownDeposit} onChange={changePayment} className="form-control">
                            <option value="cash">{translation.t('dropdown.cash')}</option>

                        </select>
                    </div>
                    <div className="col-md-4">
                        <button className='btn btn-primary' style={{ padding: '9px' }} onClick={onGetDeposit}>{t('driver.depositAmount')}</button>
                    </div>
                </div> : ""}

                {balanceAccess ? <div className="row">

                    <div style={{ marginTop: "2rem" }} className="col-md-12 form-group">
                        <div className="alert alert-success btn-block" style={{ background: '#b6ddb1b5' }} role="alert">
                            <h6>Driver Balance: {symbol} {toSubUnit(driver.balance as number)}</h6>
                        </div>
                    </div>

                </div> : ""}

                <StatsComponent
                    currentRange={time} onRangeChange={(e) => setTime(e.target.value)} onCustomSelect={onCustomSelect} data={!stats ? []: [
                        { label: t('driver.stats.distance'), value: getGeneralDistanceFormat(stats.distance), isPositive: stats.distance >= stats.prevDistance, percentage: stats.distancePercentage },
                        { label: t('driver.stats.earnings'), value: getCurrencyFormatting(stats.revenue), isPositive: stats.revenue >= stats.prevRevenue, percentage: stats.revenuePercentage },
                        { label: t('driver.stats.rides'), value: stats.tripCount, isPositive: stats.tripCount >= stats.prevTripCount, percentage: stats.tripCountPercentage },
                        { label: t('driver.stats.shiftTime'), value: getGeneralTimeFormat(stats.rideTime), isPositive: stats.rideTime >= stats.prevRideTime, percentage: stats.tripCountPercentage }
                    ]}
                 />
                {inspectionAssignment.map((inspectionAssignment) => (
                    <Checklist
                        key={inspectionAssignment.inspection?.id}
                        value={inspectionAssignment}
                        onChange={newVal}
                    />
                ))}
                <ViewContent title='Images'>
                    <ViewContentFiles
                        key={selectedDriver.id}
                        groups={[
                            { title: t('common.identity'), files: driver?.identityFiles?.map(getFileSrc) },
                            { title: t('common.policeCheck'), files: driver?.policeFiles?.map(getFileSrc) },
                            { title: t('common.license'), files: driver?.licenseFiles?.map(getFileSrc) },
                            { title: t('common.history'), files: driver?.historyFiles?.map(getFileSrc) },
                            { title: t('common.vevo'), files: driver?.vevoFiles?.map(getFileSrc) },
                            { title: t('common.medicare'), files: driver?.medicareCardFiles?.map(getFileSrc) },
                            { title: t('common.proficiency'), files: driver?.englishLanguageProficiencyFiles?.map(getFileSrc) },
                        ]}
                    />
                </ViewContent>
            </div>

        </ContentWrapper>
    );
}